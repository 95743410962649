<template>
  <div class="d-flex align-items-center ">
    <div class="langtoggle">
      <LanguageSwitch></LanguageSwitch>
    </div>
    <div v-if="coverImage !== ''" class="header-cover" :style="{
      'background-image': `url('${coverImage}')`,
      width: '100%',
      'background-size': 'cover',
      'background-position': 'center center',
    }"></div>
    <CCard class="p-3" :style="{ top: cardTop }">
      <CCardBody class="text-center">
        <div style="display: flex; justify-content: center;">
          <div class="logoImg" :style="{
      'background-image': `url('${logo}'), url('${noImg}')`,
      'background-size': 'cover',
      'background-position': 'center center',
    }"></div>
        </div>
        <h2 class="font-weight-bold mt-3">
          {{ shopName }}
        </h2>
        <h6 class="text-grey mb-4">{{ branchName }} </h6>
        <span class="h5 font-weight-normal">
          <i class="fi fi-rr-utensils"></i>
          {{ tableName }} &emsp; | &emsp;
          <i class="fi fi-rr-user"></i>
          {{ pax }} {{ $t('guest') }}
        </span><br>
        <CButton :disabled="loading" class="mt-5 btn-startorder p-3 " style=" font-size: 20px;color: white;" block
          @click="openTable">
          <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" /> &nbsp;
          {{ $t('startOrder') }}
        </CButton>
        <div class="row">
          <div class="col-6">
            <CButton class="mt-3 btn-square-home" @click="goToCart">
              <i class="fi fi-rr-shopping-basket" style="font-size: 30px;"></i>
              <br>
              {{ $t('orderItemNav') }}
            </CButton>
          </div>
          <div class="col-6">
            <CButton class="mt-3 btn-square-home " @click="() => { callStaffModal = true }">
              <img src="@/assets/icons/bell.png" class="img-fluid pb-2" style="width: 34px;margin-bottom: 6px;">
              <br>
              {{ $t('callStaff') }}
            </CButton>
          </div>
        </div>
        <small hidden>{{ timestamp }}</small>
        <div class="mt-5">
          <span>
            Powered by
            <img src="/logo.png" width="30%" alt="" class="img-fluid ml-1" />
          </span>
        </div>
      </CCardBody>
    </CCard>
    <call-staff v-bind:callStaffModal="callStaffModal" @update-status="callStaffModal = false"></call-staff>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import pos from '@/services/pos'
import qs from 'qs'
import moment from 'moment'
import '@/util/menu.css'
import CallStaff from '../dine-in/CallStaff'
import LanguageSwitch from '@/components/AppLanguageSwitch'

export default {
  components: {
    CallStaff,
    LanguageSwitch
  },
  data() {
    return {
      table: {},
      receiptId: '',
      receiptObjectId: '',
      callStaffModal: false,
      showDropdown: false,
      coverImage: '',
      cartLists: [],
      loading: false,
      screenWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    cardTop() {
      return `${this.screenWidth / 5}px`;
    },
    uid() {
      let uid = this.$route.query.uid
      localStorage.setItem('uid', uid)
      return uid
    },
    shopObjectId() {
      let objectId = this.$route.query.shopObjectId
      localStorage.setItem('shopObjectId', objectId)
      return objectId
    },
    tableObjectId() {
      localStorage.setItem('tableObjectId', this.$route.query.tableObjectId)
      return this.$route.query.tableObjectId
    },
    pax() {
      let paxx = this.$route.query.pax
      localStorage.setItem('pax', paxx)
      return paxx
    },
    timestamp() {
      let time = this.$route.query.time
      localStorage.setItem('timestamp', time)
      return time
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        localStorage.setItem('shopName', this.shop.shopName)
        return this.shop.shopName
      } else {
        return ''
      }
    },
    logo() {
      const shop = this.shop || {}
      if (shop.remoteImagePath) {
        return shop.remoteImagePath
      } else {
        return 'https://cdn-icons-png.flaticon.com/512/4711/4711299.png'
      }
    },
    branchName() {
      if (this.shop) {
        return this.shop.branchName
      } else {
        return ''
      }
    },
    tableName() {
      if (this.table) {
        return this.table.name
      } else {
        return ''
      }
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  beforeCreate() {
    this.$store.dispatch('getShopLineOA')
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    if (this.cartLists.length == 0) {
      sessionStorage.clear()
    }
  },
  created() {
    this.getTableStatusFromFB()
    this.getQRTimeStamp()
    this.getOrderShopSetting()
    this.getName()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    getName() {
      if (!localStorage.getItem('name')) {
        try {
          pos({
            method: 'get',
            url: '/auth/v1.0/session/tempname',
            params: { tableObjectId: this.tableObjectId }
          }).then((res) => {
            let name = res.data?.data?.name || 'A'
            localStorage.setItem('name', name)
          })
        } catch (error) {
          throw new Error(error)
        }
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async getOrderShopSetting() {
      const uid = `${localStorage.getItem('uid')}`
      const shopObjectId = `${localStorage.getItem('shopObjectId')}`

      let params = {
        shopObjectId: shopObjectId,
      }
      try {
        await poscrm({
          method: 'get',
          url: '/api/v2.1/' + uid + '/OrderShopSetting',
          params: params,
        }).then((res) => {
          this.coverImage = res.data?.data?.[0]?.remoteCoverImagePath || '';
          const themeColor = res.data?.data[0]?.themeColor || '#29B46B'
          $(':root').css('--bg-color-theme', themeColor);
        })
      } catch (error) {
        throw new Error(error)
      }


    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.showDropdown = false
      this.trackButtonClick('home/change_lang_to_' + locale)
    },

    goToCart() {
      this.trackButtonClick('home/mycart')
      this.$router.push("/dine-in/mycart")
    },

    async openTable() {
      this.trackButtonClick('home/open_table')
      this.loading = true
      const uid = this.uid

      let data = {
        shopObjectId: this.shopObjectId,
        tableObjectId: this.table.objectId,
        status: 1,
        receiptId: this.receiptId,
        receiptObjectId: this.receiptObjectId,
      }

      try {
        await poscrm({
          url: '/api/v1.1/' + uid + '/Shop/updatetablestatus',
          data: qs.stringify(data),
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((res) => {
          this.$router.push('/dine-in/menulists')
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async getTableStatusFromFB() {
      let params = {
        shopObjectId: this.shopObjectId,
        objectId: this.tableObjectId,
      }

      try {
        await poscrm
          .get('/api/v1.1/' + this.uid + '/Shop/gettablebyobjectid/fbdata', {
            params: params,
          })
          .then((res) => {
            if (res.data?.error?.code == 0) {
              let response = {}
              response = res.data?.data

              let tabledata = {
                objectId: response.objectId,
                id: response.id,
                name: response.name,
              }

              this.table = tabledata
              localStorage.setItem('table', JSON.stringify(tabledata))

              let lastUpdated = moment(response.lastUpdated)
              let checkQTStatus = moment(this.timestamp).isSameOrAfter(lastUpdated)



              if (response.currentReceipt == null) {
                if (checkQTStatus) {
                  sessionStorage.clear()
                  localStorage.clear()
                  window.location.href = '/texp?errorCode=-1002'
                } else {
                  localStorage.removeItem('receiptId')
                  localStorage.removeItem('receiptObjectId')

                  localStorage.removeItem('creditCardPayStatus')
                  localStorage.removeItem('paymentStatus')
                }
              } else {
                let currentReceipt = response.currentReceipt
                this.receiptId = currentReceipt.id
                this.receiptObjectId = currentReceipt.objectId

                localStorage.setItem('receiptId', this.receiptId)
                localStorage.setItem('receiptObjectId', this.receiptObjectId)
              }
            } else {
              alert('ติดต่อพนักงานของเราเพื่อขอความช่วยเหลือ')
              sessionStorage.clear()
              localStorage.clear()
              window.location.href = '/texp?errorCode=-1001'
            }
          })
      } catch (error) {
        throw new Error(error)
      }


    },
    getQRTimeStamp() {
      let uid = this.uid || null
      let timestamp = this.timestamp

      if (uid == null) {
        localStorage.clear()
        sessionStorage.clear()
        this.$router.push('/texp?errorCode=-1003')
      } else {
        let params = {
          shopObjectId: this.shopObjectId,
          QRTimestamp: timestamp,
        }
        try {
          poscrm
            .get('/api/v1.1/' + uid + '/Receipt/order/qrtimestamp', {
              params: params,
            })
            .then((res) => {
              if (res.data?.data != null) {
                let document = {}
                document = res.data?.data

                // if (document.QRTimestamp === timestamp) {
                if (document.receiptStatus === 'A') {
                  localStorage.clear()
                  sessionStorage.clear()
                  this.$router.push('/texp?errorCode=-1000')
                } else if (document.deleted_at != null) {
                  localStorage.clear()
                  sessionStorage.clear()
                  this.$router.push('/texp?errorCode=-1004')
                }
                // }
              }
            })
        } catch (error) {
          throw new Error(error)
        }

      }
    },
  },
}
</script>

<style>
.logoImg {
  background-color: rgb(255, 255, 255);
  width: 50%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.header-cover {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  aspect-ratio: 2/1;

  /* Set height to 50% of width */
}

.langtoggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  padding: 20px;
}

.card {
  background-color: unset;
  border: 0cap;
  overflow-y: scroll;
  z-index: 1000;
}
</style>
